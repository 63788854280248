define("egift-frontend/instance-initializers/datadog-rum", ["exports", "@datadog/browser-rum", "egift-frontend/utils/environment", "egift-frontend/utils/datadog"], function (_exports, _browserRum, _environment, _datadog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var APPLICATION_ID = 'ac22af1d-88d0-42fc-b0d2-c04fd5c48a1d';
  var CLIENT_TOKEN = 'pub8ff54dc9065e6027aec8e51c3856f658';
  var SERVICE = 'egift-frontend';
  var SITE = 'datadoghq.com';
  function initialize() {
    var environment = (0, _environment.getEnvironmentName)();

    // we only want to initialize datadog in staging and production environments
    if (!(environment === _environment.ENVIRONMENT_NAME.STAGING || environment === _environment.ENVIRONMENT_NAME.PRODUCTION)) {
      return;
    }
    _browserRum.datadogRum.init({
      applicationId: APPLICATION_ID,
      clientToken: CLIENT_TOKEN,
      compressIntakeRequests: true,
      env: environment,
      excludedActivityUrls: [].concat(_toConsumableArray((0, _datadog.getBackgroundRequestsRegexes)()), [
      // Exclude external URLs
      _datadog.isSquareExternalHost]),
      service: SERVICE,
      site: SITE,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    _browserRum.datadogRum.startSessionReplayRecording();
  }
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});