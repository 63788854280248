define("egift-frontend/utils/datadog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBackgroundRequestsRegexes = getBackgroundRequestsRegexes;
  _exports.isSquareExternalHost = isSquareExternalHost;
  // Returns a list of regexes that match known requests made in the background, to be excluded from activity detection.
  function getBackgroundRequestsRegexes() {
    return [/\/v1\/batch$/, /\/v1\/cdp\/batch$/,
    // CDP V1
    /\/1\.0\/unifiedevents\/batch$/,
    // CDP V2
    /\/2\.0\/log\/pixel_tracking/ // eventstream
    ];
  }

  // Checks if a URL is external to Square and thus should be excluded from activity detection
  function isSquareExternalHost(url) {
    try {
      var urlObject = new URL(url);
      var host = urlObject.hostname;
      return !host.includes('square');
    } catch (_unused) {
      return false;
    }
  }
});